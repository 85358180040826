/* general styling */
body {
  background-color: #000;
  color: #fff;
  font-family: 'Inconsolata', monospace;
  font-size: 30px;
  margin: 0;
  padding: 0;
}

#terminal, #editor-buffer {
  margin-top: 30px;
  margin-left: 30px;
}

li {
  list-style-type: none;
  margin-left: 0px;
}

a {
  color: #53f4ef;
}

a:hover {
  color: #99faff;
}

.cursor {
  color: #000;
  background-color: #fff;
}

.blink {
  background-color: #fff;
  color: inherit;
}

.user {
  color: #56f442;
}

.path, .dir {
  color: #3c79b2;
}

.inline {
  display: inline-block;
  margin-right: 35px;
}

.ls:hover {
  cursor: pointer;
  opacity: .85;
}

.txt {
  max-width: 75%;
}

.underline {
  text-decoration: underline;
  text-align: center;
}

/* styling for non-terminal part of site */
.header {
  text-align: center;
  margin: 20px;
}

.header-btn {
  display: inline-block;
  margin: 0px 20px;
}

/* styling for editor */
#editor {
  display: none;
}

#editor-console {
  background-color: #222;
  width: 100%;
  height: 1em;
  position: fixed;
  bottom: 0;
}

bl {
  display: block;
}

bc {
  display: inline;
}
